export default {
  heading: 'Roboto Condensed, serif',
  body: 'Roboto Condensed, sans-serif',
  monospace: 'Roboto Condensed, monospace',
  display: 'Roboto Condensed, serif',
  display2: 'Roboto Condensed, sans-serif',
  display3: 'Roboto Condensed, sans-serif',
  googleFonts: ['Roboto Condensed:300,400,700'],
  customFamilies: [],
  customUrls: [],
};
